import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";

import { showError } from "../../toastify";

import { doCancelPayroll } from "../../../store/payroll/actions";

const CancelPayrollDialog = (props) => {
  const {
    loading,
    showCancelPayrollForm,
    setShowCancelPayrollForm,
    doCancelPayroll,
    selectedPayroll
  } = props;

  const [formData, setFormData] = useState({
    password: "",
    payrollNumber: ""
  });
  const [errors, setErrors] = useState({
    passwordHasError: false,
  });

  const formValidator = () => {
    const error = {
      passwordHasError: false,
    };

    if (!formData.password) {
      error.passwordHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onCancelPayroll = (e) => {
    e.preventDefault();
    if (!formValidator()) {
      formData.payrollNumber = selectedPayroll.id;
      doCancelPayroll(formData, setShowCancelPayrollForm);
    }
  };

  const onClose = () => {
    setShowCancelPayrollForm(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCancelPayrollForm}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle className="text-primary font-weight-bold">
          <span className="d-flex align-items-center">
            <span className="material-icons mr-1">error</span> Confirm
          </span>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-1">
          <Typography className="font-weight-bold text-center">
            Are you sure you want to <span className="text-danger">Cancel</span>{" "}
            this payroll?
          </Typography>
          <span className="d-block text-center">
            ({selectedPayroll.payrollTypeName} {selectedPayroll.periodId})
          </span>

          <form onSubmit={onCancelPayroll}>
            <div
              className="elevated rounded p-3 mt-2"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              <div className="row">
                <div className="col-12 px-5">
                  <TextField
                    fullWidth
                    size="small"
                    rows={4}
                    autoFocus
                    name="password"
                    type="password"
                    label="Enter your password to confirm"
                    variant="outlined"
                    placeholder="Enter your password to confirm"
                    value={formData.password}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        password: e.target.value || "",
                      });
                      setErrors({ ...errors, passwordHasError: false });
                    }}
                  />

                  {errors.passwordHasError && (
                    <small className="text-danger">Password is required </small>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center py-3">
              <button
                type="submit"
                className="btn btn-primary text-uppercase  px-4"
                disabled={loading}
              >
                {loading ? "Canceling..." : "Confirm"}
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, { doCancelPayroll })(
  CancelPayrollDialog
);
